@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-video {
  margin-bottom: spacing.$xl;

  &__form-wrapper {
    padding: spacing.$m;
    background: light.$surface-primary-default;
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    border-radius: corner-radius.$s;
  }

  &__input-wrapper {
    width: 100%;

    @media (min-width: 600px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: spacing.$m;
  }

  &__select {
    max-width: 300px;
  }

  &__upload-button {
    width: 100%;
    max-width: 150px;
  }

  &__terms {
    margin-top: spacing.$m;
    margin-bottom: spacing.$l;
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    padding: spacing.$m;
    background: light.$surface-primary-default;
    border-radius: corner-radius.$s;
  }

  &__guidelines-text {
    color: light.$on-surface-warning;
  }

  &__links {
    display: flex;
    gap: spacing.$s;
  }

  &__modal-content {
    > * + * {
      margin-top: spacing.$s;
    }
  }
}
